import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import RoutesComponent from "./RoutesComponent";
import LoginComponent from "./components/Login";
import tokenApi from "./utils/tokenApi";
import { CONFIG, PATHS } from "./utils/env";
import Loader from "./components/Loader";
import Unauthenticated from "./components/Unauthenticated";
import UnAuthorized from "./components/UnAuthorized";
import { Mid } from "@mid/sdk";
import { useSearchParams } from "react-router-dom";
import { updateState, updateToken } from "./store/login";
import SessionStore from "./common/SessionStore";
import AuthStore from "./common/AuthStore";
import { parseJwt } from "./utils/function";
import useInterval from "./common/useInterval";

interface Props {
  mid: Mid;
}

const App = ({ mid }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const access_token = searchParam.get("access_token") || SessionStore.accessToken || "";
  const source = searchParam.get("source") || SessionStore.source || "";
  const user_id = searchParam.get("user_id") || SessionStore.userId || "";
  const first_name = searchParam.get("first_name") || SessionStore.firstName || "";
  const last_name = searchParam.get("last_name") || SessionStore.lastName || "";
  const lp_id = searchParam.get("lp_id") || SessionStore.lpId || "";
  const [isLoading, setIsLoading] = useState(true);
  const [tokenValue, setTokenValue] = useState("");

  const getRefreshToken = async () => {
    const access_token = SessionStore.accessToken || AuthStore.accessToken;
    const source = SessionStore.source;
    if (access_token) {
      if (source === "docebo") {
        const payload = {
          client_id: CONFIG?.SERVICE_ACCOUNT_CLIENT_ID ?? "",
          client_secret: CONFIG?.SERVICE_ACCOUNT_CLIENT_SECRET ?? "",
          grant_type: "client_credentials"
        };
        const response = await tokenApi(CONFIG.TOKEN_URL, "POST", "", payload);
        const { access_token } = response;
        dispatch(updateToken({ access_token }));
        SessionStore.accessToken = access_token;
      } else {
        mid.refresh();
        const newToken = mid.accessToken();
        if (newToken) {
          const infoFromToken = parseJwt(newToken);
          const { given_name = "", family_name = "", email = "" } = infoFromToken;
          dispatch(
            updateState({ access_token: newToken, source: "", user_id: email, lp_id: "", first_name: given_name, last_name: family_name })
          );
        }
      }
    }
  };

  const validateToken = async (token: string) => {
    try {
      if (token) {
        const response = await tokenApi(CONFIG.TOKEN_VALIDATION, "GET", token);
        if (response !== null) {
          setTokenValue("valid");
          navigate(PATHS.ACADEMY);
        } else {
          setTokenValue("invalid");
        }
        setIsLoading(false);
      }
    } catch (error: any) {
      if (error.message === "Signature Expired") {
        setTokenValue("Signature_Expired");
      } else {
        setTokenValue("invalid");
      }
      setIsLoading(false);
      console.error("Exception occurred...!");
      console.error(error);
    }
  };

  useInterval(() => getRefreshToken(), 900000);

  useEffect(() => {
    async function checkToken(access_token: string) {
      const response = await validateToken(access_token);
      return response;
    }
    if (access_token && source === "docebo") {
      checkToken(access_token);
      dispatch(updateState({ access_token, source, user_id, first_name, last_name, lp_id }));
      SessionStore.accessToken = access_token;
      SessionStore.firstName = first_name;
      SessionStore.lastName = last_name;
      SessionStore.source = source;
      SessionStore.lpId = lp_id;
      SessionStore.userId = user_id;
    } else {
      setIsLoading(false);
    }
  }, [access_token, source]);
  if (isLoading) return <Loader position='fixed' showBackground background='transparent' />;
  if (tokenValue === "valid") return <RoutesComponent mid={mid} />;
  if (tokenValue === "invalid") return <Unauthenticated />;
  if (tokenValue === "Signature_Expired") return <UnAuthorized source={source} />;
  return (
    <LoginComponent mid={mid} data-testid='login'>
      <RoutesComponent mid={mid} />
    </LoginComponent>
  );
};

export default React.memo(App);
